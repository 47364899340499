import * as Sentry from "@sentry/react";
import "animate.css/animate.min.css";
import Bluebird from "bluebird";
import "nprogress/nprogress.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/index.css";
// import './__mocks__';
import { StyledEngineProvider } from "@mui/material/styles";
import { StrictMode } from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { AuthProvider } from "./contexts/JWTContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { TradingViewProvider } from "./contexts/TradingViewContext";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./style/global.css";

Bluebird.config({
  warnings: {
    wForgottenReturn: false,
  },
  longStackTraces: true,
  cancellation: true,
  monitoring: true,
});

Bluebird.onPossiblyUnhandledRejection((error) => {
  console.error("Unhandled Rejection Detected:", error);
});

// Substitui a implementação padrão de Promises pelo Bluebird
window.Promise = Bluebird as any;

Sentry.init({
  dsn: "https://77572c869264148fa3eadca70c5eeae9@o4507940454596608.ingest.us.sentry.io/4507965984342016",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1, // Capture 10% of the transactions
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/api\.ebinex\.com/,
    /^https:\/\/api-testnet\.ebinex\.com/,
  ],
  profilesSampleRate: 0.1, // Profile 10% of the transactions
  replaysSessionSampleRate: 0.05, // Sample rate at 5%
  replaysOnErrorSampleRate: 1.0, // Sample rate at 100% on error
  beforeSend(event) {
    const filteredErrors = [
      "Non-Error promise rejection captured with value: Internal error",
      "Method not found",
      "Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "Non-Error promise rejection captured with value: Network error, verify your internet connection",
    ];
    if (
      event.message &&
      filteredErrors.some((error) => event.message.includes(error))
    ) {
      return null;
    }
    return event;
  },
});

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <CookiesProvider>
        <StyledEngineProvider injectFirst>
          <SettingsProvider>
            <LayoutProvider>
              <BrowserRouter>
                <AuthProvider>
                  <TradingViewProvider>
                    <App />
                  </TradingViewProvider>
                </AuthProvider>
              </BrowserRouter>
            </LayoutProvider>
          </SettingsProvider>
        </StyledEngineProvider>
      </CookiesProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
