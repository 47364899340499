import { experimentalStyled } from "@mui/material/styles";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { AccountProvider } from "src/contexts/AccountContext";
import { ApiProvider } from "../../contexts/ApiContext";
import DashboardNavbar from "./DashboardNavbar";
import { DepositPromotionBanner } from "src/layout/Banner";
import { Footer } from "src/layout/Footer";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const DashboardLayoutWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  // paddingTop: '64px',
  paddingTop: "1rem",
  // [theme.breakpoints.up('lg')]: {
  //   paddingLeft: '280px'
  // }
}));

const DashboardLayoutContainer = experimentalStyled("div")({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
});

const DashboardLayoutContent = experimentalStyled("div")({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
  position: "relative",
  WebkitOverflowScrolling: "touch",
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] =
    useState<boolean>(false);

  return (
    <ApiProvider>
      <AccountProvider>
        <DashboardLayoutRoot>
          <DashboardNavbar
            onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
          />
          <DashboardLayoutWrapper>
            <DashboardLayoutContainer>
              <DashboardLayoutContent>
                <Outlet />
                <Footer />
                {/* <DashboardButtonBar /> */}
              </DashboardLayoutContent>
            </DashboardLayoutContainer>
          </DashboardLayoutWrapper>
        </DashboardLayoutRoot>
      </AccountProvider>
    </ApiProvider>
  );
};

export default DashboardLayout;
