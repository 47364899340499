export const footerMenuOptions = [
  {
    title: "Empresa",
    subItens: [
      {
        label: "Carreiras",
        url: "#",
      },
      {
        label: "Nossa visão",
        url: "#",
      },
    ],
  },
  {
    title: "Produtos",
    subItens: [
      {
        label: "Mercado de opções",
        url: "#",
      },
      {
        label: "Exchange",
        url: "#",
      },
      {
        label: "Lottery",
        url: "#",
      },
      {
        label: "Prediction",
        url: "#",
      },
      {
        label: "Carteira",
        url: "#",
      },
    ],
  },
  {
    title: "Aprender",
    subItens: [
      {
        label: "Educação",
        url: "#",
      },
      {
        label: "Blog",
        url: "#",
      },
    ],
  },
  {
    title: "Suporte",
    subItens: [
      {
        label: "Fale conosco",
        url: "#",
      },
      {
        label: "Regras",
        url: "#",
      },
      {
        label: "Telegram",
        url: "#",
      },
      {
        label: "Enviar email",
        url: "#",
      },
    ],
  },

  {
    title: "Jurídico",
    subItens: [
      {
        label: "Termos e condições",
        url: "#",
      },
      {
        label: "Políticas de privacidade",
        url: "#",
      },
      {
        label: "Licença regulatória",
        url: "#",
      },
    ],
  },
];
