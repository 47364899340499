import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ReactNode } from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiscord, FaInstagram, FaXTwitter } from "react-icons/fa6";
import { footerMenuOptions } from "./footerMenuOptions";
import Logo from "src/components/Logo";

export function Footer() {
  return (
    <Box component={Stack} borderTop="1px solid #15181A">
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          gap={2}
        >
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            gap={2}
            component="header"
          >
            <Logo sx={{ height: 48 }} />
            <Stack direction="row" gap={1.75} alignItems="center">
              <IconSocialMedia
                icon={<FaInstagram size={18} color="#E7E8E8" />}
                url="https://www.instagram.com/ebinex.br/"
              />
              <IconSocialMedia
                icon={<FaTelegramPlane size={18} color="#E7E8E8" />}
                url=""
              />
              <IconSocialMedia
                icon={<FaXTwitter size={18} color="#E7E8E8" />}
                url="https://x.com/ebinexmarket"
              />
              <IconSocialMedia
                icon={<FaDiscord size={18} color="#E7E8E8" />}
                url="https://x.com/ebinexmarket"
              />
            </Stack>
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            flexWrap="wrap"
            gap={{ xs: 2.5, sm: 10 }}
          >
            {footerMenuOptions.map((menu) => (
              <FooterMenuSingle
                key={menu.title}
                title={menu.title}
                subItens={menu.subItens}
              />
            ))}
          </Stack>
        </Stack>
      </Container>
      <Stack
        direction="row"
        flexWrap="wrap"
        py={2}
        alignItems="center"
        justifyContent="center"
        borderTop="1px solid #15181A"
        gap={2}
      >
        <Typography fontSize={12} color="#989C9F">
          EBINEX LLC: Registro nº 3195 LLC 2024; Euro House, Richmond Hill Rd,
          Kingstown, São Vicente e Granadinas.
        </Typography>

        <Typography padding="0.25rem 1.25rem" color="#989C9F" fontSize={12}>
          E-mail para contato: support@ebinex.global
        </Typography>
      </Stack>
    </Box>
  );
}

interface FooterMenuProps {
  title: string;
  subItens: Array<{
    label: string;
    url: string;
  }>;
}

export function FooterMenuSingle({ title, subItens }: FooterMenuProps) {
  return (
    <Stack flex={1} direction="column" gap={3}>
      <Typography color="#DEE3E6" fontWeight={700}>
        {title}
      </Typography>

      {subItens.map((item) => (
        <Typography
          sx={{
            width: "fit-content",
            fontSize: 14,
            color: "#808080",
            whiteSpace: "nowrap",
            cursor: "pointer",
            "&:hover": {
              color: "#DEE3E6",
            },
          }}
          key={item.label}
        >
          {item.label}
        </Typography>
      ))}
    </Stack>
  );
}

export function IconSocialMedia({
  icon,
  url,
}: {
  icon: ReactNode;
  url: string;
}) {
  return (
    <Button
      sx={{
        width: 40,
        height: 40,
        minWidth: "unset",
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        border: "1px solid #15181A",
        // padding: 1,
        "&:hover": {
          backgroundColor: "#15181A",
        },
      }}
      target="_blank"
      href={url}
    >
      {icon}
    </Button>
  );
}
