import { useContext } from "react";
import LayoutContext from "src/contexts/LayoutContext";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

const EyeShowSensitiveInfo = ({ size }: { size: number }) => {
  const { layout, setShowSensiveInfo } = useContext(LayoutContext);

  return (
    <span style={{ cursor: "pointer" }}>
      {layout.showSensiveInfo ? (
        <HiOutlineEyeOff
          className="eyeIcon"
          size={size}
          onClick={setShowSensiveInfo}
        />
      ) : (
        <HiOutlineEye
          className="eyeIcon"
          size={size}
          onClick={setShowSensiveInfo}
        />
      )}
    </span>
  );
};

export default EyeShowSensitiveInfo;
