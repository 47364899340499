import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import {
  DataGrid,
  GridColDef,
  GridExpandMoreIcon,
  GridToolbar,
} from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { format, subDays } from "date-fns";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCalendarAlt } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import Logo from "src/components/Logo";
import TextCopy from "src/components/TextCopy";
import useQuery from "src/hooks/useQuery";
import useDeposits from "src/swr/use-deposits";
import labelsColors from "src/theme/labelsColors";
import Refresh from "../../../icons/Refresh";
import TitleWithCircleIcon from "src/components/TitleWithCircleIcon";
import { BsClockHistory } from "react-icons/bs";

const STATUS_LIST = ["all_statuses", "CONFIRMED", "PENDING", "EXPIRED"];

const INIT_FILTER_DATA = {
  depositStatus: STATUS_LIST[0],
  dateFrom: subDays(new Date(), 30),
  dateTo: new Date(),
};

const DepositListTable = () => {
  const { t } = useTranslation("dashboard");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const query = useQuery();
  const depositId = query.get("depositId");
  const status = query.get("status");

  const [showDatePickers, setShowDatePickers] = useState(false);
  const [dateTo, setDateTo] = useState(INIT_FILTER_DATA.dateTo);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [activeFiltersData, setActiveFiltersData] = useState(INIT_FILTER_DATA);
  const [dateFrom, setDateFrom] = useState(INIT_FILTER_DATA.dateFrom);
  const [sortModel, setSortModel] = useState([
    { field: "createdAt", sort: "DESC" },
  ]);

  const [queryFormat, setQueryFormat] = useState("");

  const dataGridColumns: GridColDef[] = [
    {
      field: "gatewayTransactionId",
      headerName: t("pix_transaction_id"),
      flex: isMobile ? undefined : 1,
      width: isMobile ? 125 : undefined,
      renderCell: (params) => (
        <TextCopy
          text={{ id: String(params.id), value: params.value as string }}
        />
      ),
    },
    {
      field: "createdAt",
      headerName: t("date"),
      flex: isMobile ? undefined : 1,
      width: isMobile ? 100 : undefined,
      renderCell: (params) => {
        if (!params.value) return "";

        const formattedDate = format(new Date(params.value), "dd/MM/yyyy");
        const formattedTime = format(new Date(params.value), "HH:mm:ss");

        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{formattedDate}</span>
            <span style={{ fontSize: "0.8rem", color: "#677985" }}>
              {formattedTime}
            </span>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: t("value"),
      flex: isMobile ? undefined : 1,
      width: isMobile ? 120 : undefined,
      valueFormatter: (params) =>
        params.value != null
          ? `$ ${numeral(params.value).format("0,0.00")}`
          : "",
    },
    {
      field: "gatewayTransactionType",
      headerName: t("type"),
      flex: isMobile ? undefined : 1,
      width: isMobile ? 100 : undefined,
      renderCell: (params) => (
        <span
          style={{
            fontWeight: 500,
            color:
              params.value === "PIX"
                ? "#00A667"
                : params.value === "CRYPTO"
                ? "#ffb92e"
                : "transparent",
          }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: isMobile ? undefined : 1,
      width: isMobile ? 150 : undefined,
      renderCell: (params) => (
        <Chip
          label={t(String(params.value).toLowerCase())}
          sx={{
            ...labelsColors[params.value],
            fontWeight: 400,
            fontSize: 14,
          }}
        />
      ),
    },
  ];

  const fetchDeposits = async () => {
    const queryFormat = formatQueryParams();
    setQueryFormat(queryFormat);
  };

  const {
    data: deposits,
    totalElements,
    loading: loadingDeposits,
  } = useDeposits({
    query: queryFormat,
    page,
  });

  useEffect(() => {
    fetchDeposits();
  }, [activeFiltersData, depositId, status, pageSize, sortModel]);

  const formatQueryParams = () => {
    let query = "";

    const { depositStatus, dateFrom, dateTo } = activeFiltersData;

    query =
      query +
      `&depositStatuses=${
        depositStatus === "all_statuses"
          ? "CONFIRMED,PENDING,EXPIRED"
          : depositStatus
      }`;

    query = query + `&from=${format(dateFrom, "yyyy-MM-dd")}`;
    query = query + `&to=${format(dateTo, "yyyy-MM-dd")}`;
    query = query + `&size=${pageSize}`;

    if (sortModel.length !== 0) {
      query =
        query +
        `&sortAttributeName=${
          sortModel[0].field
        }&sortMode=${sortModel[0].sort.toUpperCase()}`;
    } else {
      query = query + `&sortAttributeName=createdAt&sortMode=DESC`;
    }

    return query;
  };

  const changeSelect = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setActiveFiltersData({
      ...activeFiltersData,
      [name]: value,
    });
  };

  const handleChangeDate = (date: Date, name: string) => {
    setActiveFiltersData({
      ...activeFiltersData,
      [name]: date,
    });
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    fetchDeposits();
  };

  const handlePageSize = (size) => {
    setPageSize(size);
  };

  const handleShowDatePickers = () => {
    setShowDatePickers(!showDatePickers);
  };

  return (
    <>
      <Box
        className="accordion_item"
        sx={{
          p: 2,
          background: "#070f14",
          color: "#EEE",
          borderRadius: "8px",
          "& .MuiPaper-root": {
            background: "transparent",
          },
        }}
      >
        <Accordion elevation={0}>
          <AccordionSummary expandIcon={<GridExpandMoreIcon />}>
            <TitleWithCircleIcon
              label="Histórico"
              fontSize={18}
              icon={<BsClockHistory />}
              circleSize={32}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                background: "#070f14",
                padding: "1.5rem",
                borderRadius: "8px",
                "& .MuiInputBase-root": {
                  backgroundColor: "#0c151a",
                },
                "& fieldset": {
                  border: "none",
                  outline: "none",
                },
                "& .MuiDataGrid-root": {
                  border: "none",
                  fontSize: "0.875rem",
                },
                "& .MuiDataGrid-toolbarContainer": {
                  justifyContent: isMobile && "space-between",
                  columnGap: isMobile ? "0.5rem" : "1rem",
                  padding: 0,
                  paddingTop: 2,
                },
                "& .MuiDataGrid-toolbarContainer button": {
                  width: isMobile ? "47%" : "initial",
                  fontSize: "0.875rem",
                  background: "#0c151a",
                  marginBottom: "1rem",
                  padding: isMobile ? "1rem" : "0.5rem 1rem",
                  "&:hover": {
                    outline: "2px solid #0ab575",
                  },
                },
                ".MuiDataGrid-topContainer::after": {
                  background: "transparent",
                },
                ".MuiDataGrid-footerContainer": {
                  borderColor: "#11181d",
                },
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#09141a",
                },
                "& .MuiDataGrid-cell": {
                  color: "#CCC",
                  display: "flex",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  borderColor: "rgb(34, 34, 34)",
                  border: "none",
                  outline: "none !important",
                },
                "& .MuiDataGrid-cell:focus": {
                  outline: " none",
                },
                "& .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader": {
                  backgroundColor: "#0f181e",
                  border: "none",
                },
                "& .btn_date_wrapper": {
                  width: isMobile ? "100%" : "160px",
                  padding: isMobile ? "1rem 0" : "initial",
                  display: "flex",
                  gap: "0.5rem",
                  height: "51px",
                  background: "#0c151a",
                },
                "& .MuiDataGrid-withBorderColor": {
                  border: "none",
                },
                ".MuiDataGrid-filterForm": {
                  display: "flex",
                  flexDirection: "column",
                },
                "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal": {
                  backgroundColor: "#FFF",
                  height: 8,
                  "&::-webkit-scrollbar": {
                    width: 4,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "transparent",
                  },
                },
              }}
            >
              <Backdrop
                sx={{
                  color: "#fff",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "center",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loadingDeposits}
              >
                <Logo />
                <CircularProgress color="inherit" sx={{ mt: 4 }} />
              </Backdrop>

              <Stack
                direction={isMobile ? "column" : "row"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                  width: isMobile ? "100%" : "initial",
                }}
                spacing={2}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "1.25rem",
                      fontWeight: 500,
                      color: "#EEE",
                    }}
                  >
                    {t("deposit_history")}
                  </Typography>
                  {deposits.length > 0 && (
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      pt={1}
                      fontFamily="Epilogue"
                      sx={{ color: "#677985" }}
                    >
                      {`Total de depósitos: ${totalElements}`}
                    </Typography>
                  )}
                </Box>

                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "0.5rem",
                    width: isMobile ? "100%" : "initial",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                  spacing={2}
                >
                  <FormControl
                    fullWidth
                    sx={{
                      minWidth: 120,
                      maxWidth: isMobile ? "100%" : 200,
                      pt: isMobile && 1,
                    }}
                  >
                    <Select
                      labelId="select-coin"
                      id="select-statuse"
                      name="depositStatus"
                      value={activeFiltersData.depositStatus}
                      onChange={changeSelect}
                    >
                      {STATUS_LIST.map((status) => (
                        <MenuItem key={status} value={status}>
                          {t(status.toLowerCase())}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {!isMobile && !showDatePickers && (
                    <Button
                      onClick={handleShowDatePickers}
                      className="btn_date_wrapper"
                    >
                      <FaCalendarAlt size={20} />
                      <Typography variant="body1">Data</Typography>
                    </Button>
                  )}

                  {showDatePickers || isMobile ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "1rem",
                        position: "relative",
                        margin: isMobile && "0.5rem 0 0 !important",
                        flexDirection: ["column", "row"],
                      }}
                    >
                      {!isMobile && (
                        <IoMdCloseCircle
                          size={20}
                          onClick={handleShowDatePickers}
                          style={{
                            color: "#1af8a7",
                            position: "absolute",
                            right: 0,
                            top: -24,
                            cursor: "pointer",
                          }}
                        />
                      )}
                      <FormControl fullWidth sx={{ minWidth: 120, margin: 0 }}>
                        <MobileDatePicker
                          label={t("from")}
                          inputFormat="dd/MM/yyyy"
                          value={dateFrom}
                          onChange={(e) => setDateFrom(e)}
                          onAccept={(value) =>
                            handleChangeDate(value, "dateFrom")
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                      <FormControl fullWidth sx={{ minWidth: 120, margin: 0 }}>
                        <MobileDatePicker
                          label={t("until")}
                          inputFormat="dd/MM/yyyy"
                          value={dateTo}
                          onChange={(e) => setDateTo(e)}
                          onAccept={(value) =>
                            handleChangeDate(value, "dateTo")
                          }
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </FormControl>
                    </Box>
                  ) : null}
                  {!isMobile &&
                    (loadingDeposits ? (
                      <CircularProgress size={25} sx={{ mt: 0.5, mr: 1 }} />
                    ) : (
                      <IconButton
                        aria-label="refresh"
                        onClick={() => fetchDeposits()}
                        sx={{
                          background: "#0a1014",
                          width: "50px",
                          height: "50px",
                          "&:hover": {
                            background: "#0a1014",
                            outline: "2px solid #0ab575",
                          },
                        }}
                      >
                        <Refresh />
                      </IconButton>
                    ))}
                </Stack>
              </Stack>

              <DataGrid
                rows={deposits}
                columns={dataGridColumns}
                rowCount={totalElements}
                pageSize={pageSize}
                onPageSizeChange={handlePageSize}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25, 50]}
                paginationMode="server"
                pagination
                disableSelectionOnClick
                autoHeight
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    sx: {
                      "@media screen and (max-width: 600px)": {
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        width: "100%",

                        "& .MuiButtonBase-root": {
                          width: "auto !important",
                        },
                      },
                    },
                  },
                  filterPanel: {
                    sx: {
                      "& .MuiDataGrid-filterForm": {
                        flexDirection: "column",
                        gap: 2,
                      },
                      "& .MuiDataGrid-filterFormDeleteIcon": {
                        width: "max-content",
                        alignSelf: "flex-end",
                        margin: 0,
                      },
                      "& .MuiFormControl-root": {
                        width: "auto",
                      },
                    },
                  },
                  pagination: {
                    SelectProps: {
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        getContentAnchorEl: null,
                      },
                    },
                  },
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                onSortModelChange={(model) => {
                  setSortModel(model);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default DepositListTable;
